import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import Fade from 'react-reveal/Fade';
import SiteMetadata from 'src/components/SiteMetadata.jsx';
import Layout from 'src/layouts/Layout';
import Button from 'src/components/Button';
import MAHHLogo from 'src/images/mahh-logo-512.png';
import './contact.scss';

function Contact() {
  // States
  const [loading, setLoading] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  // Error from emailjs
  const [errorServer, setErrorServer] = useState('');

  // Refs to store values
  const name = useRef(null);
  const email = useRef(null);
  const phone = useRef(null);
  const message = useRef(null);

  // const filterNumbersOnly = (text) => {
  //   return text.replace(/\D/g, '');
  // };

  /** Reset Local State -> Error states */
  const resetErrorState = () => {
    setErrorName(false);
    setErrorEmail(false);
    setErrorMessage(false);
    setErrorServer('');
  };

  /** Handle Submit To EmailJS */
  const handleSubmit = event => {
    // Reset state
    resetErrorState();

    // Event
    event.preventDefault();

    // Get Ref Value
    const nameValue = name.current.value;
    const emailValue = email.current.value;
    const phoneValue = phone.current.value;
    const messageValue = message.current.value;

    if (!nameValue) {
      setErrorName(true);
    }

    if (!emailValue) {
      setErrorEmail(true);
    }

    if (!messageValue) {
      setErrorMessage(true);
    }

    if (!nameValue || !emailValue || !messageValue) {
      return;
    }

    let data = {
      from_name: nameValue,
      email: emailValue,
      to_name: 'MAHH',
      phone: phoneValue,
      message: messageValue,
    };

    // Loading
    setLoading(true);

    emailjs
      .send(
        process.env.GATSBY_SERVICE_ID,
        process.env.GATSBY_TEMPLATE_ID,
        data,
        process.env.GATSBY_USER_ID
      )
      .then(
        function(response) {
          console.log(response.status, response.text);
          // Show snackbar and turn off loading
          if (response.status === 200) {
            setLoading(false);
            setShowSnackBar(true);
            setSuccess(true);
            setTimeout(() => {
              setShowSnackBar(false);
            }, 4000);
          }
        },
        function(err) {
          console.log(err);
          setLoading(false);
          setErrorServer(JSON.stringify(err));
        }
      );
  };

  return (
    <Layout>
      <SiteMetadata
        title='Contact'
        description='MAHH Art - APEPLUS STUDIO: Bunch of creative primates producing feral digital media content based on Indonesia 🇮🇩.'
        image={MAHHLogo}
      />
      <div className='contact-page-wrapper'>
        <Fade bottom>
          <h2 className='mahh-section-title-border text-5xl text-white'>
            REACH US OUT
          </h2>
        </Fade>
        <div className='cp-content-wrapper'>
          <Fade bottom>
            <h5 className='cp-content-title font-bold'>
              What can we help you with?
            </h5>
            <form className='cp-content-form' onSubmit={handleSubmit}>
              {/* Name */}
              <div className='cp-form-wrapper'>
                <input
                  ref={name}
                  aria-label='Name'
                  name='name'
                  type='text'
                  className='cp-content-input-form'
                  placeholder='Your Name'
                  required
                />
                {errorName && (
                  <p className='error-text text-left mt-2'>
                    Please enter your name
                  </p>
                )}
              </div>
              {/* Email */}
              <div className='cp-form-wrapper'>
                <input
                  ref={email}
                  aria-label='email'
                  name='email'
                  type='email'
                  className='cp-content-input-form'
                  placeholder='Email'
                  required
                />
                {errorEmail && (
                  <p className='error-text text-left mt-2'>
                    Please enter your email
                  </p>
                )}
              </div>
              {/* Phone */}
              <div className='cp-form-wrapper'>
                <input
                  ref={phone}
                  aria-label='phone'
                  name='phone'
                  inputMode='numeric'
                  className='cp-content-input-form'
                  placeholder='Phone (Optional)'
                  type='tel'
                />
              </div>
              {/* Message */}
              <textarea
                ref={message}
                className='cp-content-input-form mb-4'
                rows='8'
                placeholder='Type your message here …'
              />
              {errorMessage && (
                <p className='error-text text-left mt-2'>
                  Please enter your message
                </p>
              )}
            </form>
          </Fade>
          {/* Button: Success | Loading | Submit */}
          {success ? (
            <Button className='background-green-success' disabled={true}>
              Sent{' '}
              <span role='img' aria-label='checkmark'>
                ✅
              </span>
            </Button>
          ) : (
            <Button
              onClick={handleSubmit}
              disabled={loading}
              className={`${
                loading
                  ? 'background-grey-ds cursor-not-allowed'
                  : 'background-red-ds'
              }`}
            >
              {loading ? 'Loading...' : 'Submit'}
            </Button>
          )}
          {errorServer && (
            <p className='error-text text-left mt-2'>
              Oops server error: {errorMessage}
            </p>
          )}
        </div>
        {/* Snackbar */}
        <div
          className={`snackbar background-green-success ${
            showSnackBar ? 'show' : ' '
          }`}
        >
          Email sent! We will reply you soon{' '}
          <span role='img' aria-label='checkmark'>
            😎
          </span>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
