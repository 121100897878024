import PropTypes from 'prop-types';
import React from 'react';

const Button = ({ children, href, className = '', ...params }) => {
  const allClassNames = `mahh-btn background-red-ds inline-block px-8 py-3 font-bold leading-snug border border-transparent text-base text-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out ${className}`;

  if (href) {
    return (
      <a
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        className={allClassNames}
      >
        {children}
      </a>
    );
  } else {
    return (
      <button className={allClassNames} {...params}>
        {children}
      </button>
    );
  }
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
};

Button.defaultProps = {
  href: null,
};

export default Button;
